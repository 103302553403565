import React from 'react';
import Button from '../Button';
import Svg from '../Svg';

type ButtonTypes = 'close';

type InfoMessageProps = {
  onCloseModal: () => void;
  title: string;
  buttons?: ButtonTypes[];
  description?: string;
};

function InfoMessage({ onCloseModal, title, description, buttons }: InfoMessageProps) {
  const getButton = (button: string, index: number) => {
    if (!button) return null;

    if (button === 'close') {
      return (
        <Button
          key={index}
          rightIcon={index === 0 ? <Svg content="double-chevron-right" /> : null}
          leftIcon={index === 1 ? <Svg content="double-chevron-left" /> : null}
          kind={'secondary'}
          onClick={onCloseModal}
        >
          Ok
        </Button>
      );
    }

    return null;
  };

  const showCloseButton = buttons?.includes('close');

  return (
    <div className="info-message">
      <header className="info-message__header">
        <h3 className="info-message__header__title">{title}</h3>
        {showCloseButton && (
          <button className="info-message__header__close" aria-label="close" onClick={onCloseModal}>
            <img src="/icons/close.svg" alt="close" />
          </button>
        )}
      </header>
      <div className="info-message__content">
        <p className="info-message__text">{description && <span>{description}</span>}</p>
        <div className="info-message__buttons-container">
          {buttons && buttons.map((button, index) => getButton(button, index))}
        </div>
      </div>
    </div>
  );
}

export default InfoMessage;
