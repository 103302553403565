import React, { useEffect, useState } from 'react';

type CounterProps = {
  value: number;
  minValue?: number;
  disableAdd?: boolean;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  onInput?: (value: number) => void;
};

function Counter({ value, minValue, disableAdd, onChange, onBlur, onInput }: CounterProps) {
  const [inputValue, setInputValue] = useState<number | ''>(value);
  const [minimumValue] = useState<number>(minValue || 0);
  const [maximumValue, setMaximumValue] = useState<number>(Infinity);
  const [isLimitingUp, setIsLimitingUp] = useState<boolean>(false);
  const [isLimitingDown, setIsLimitingDown] = useState<boolean>(false);
  const [lastPressed, setLastPressed] = useState<string | null>(null);

  useEffect(() => {
    if (disableAdd) setMaximumValue(inputValue ? inputValue : 0);
    else setMaximumValue(Infinity);
  }, [disableAdd]);

  const handleIncrease = () => {
    const newCount = inputValue ? inputValue + 1 : 1;
    if (newCount >= 0) {
      setInputValue(newCount);
      onInput && onInput(newCount);
      if (newCount > maximumValue) return;
      if (onChange) {
        onChange(newCount);
      }
    }
  };

  const handleDecrease = () => {
    const newCount = inputValue ? inputValue - 1 : 0;
    if (newCount >= minimumValue) {
      setInputValue(newCount);
      onInput && onInput(newCount);
      if (newCount <= minimumValue) return;
      if (onChange) {
        onChange(newCount);
      }
    }
  };

  const handleInputBlur = () => {
    let newValue = inputValue;

    if (!inputValue || inputValue < minimumValue) {
      newValue = minimumValue;
    } else if (inputValue > maximumValue) {
      newValue = maximumValue;
    }

    setInputValue(newValue);
    onInput && onInput(newValue || 0);
    onBlur && onBlur(newValue || 0);

    // Update maximumValue if disableAdd is true
    if (disableAdd) {
      setMaximumValue(newValue || 0);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      (isLimitingUp && lastPressed === 'ArrowUp') ||
      (inputValue === maximumValue && lastPressed === 'ArrowUp')
    )
      return;
    if (
      (isLimitingDown && lastPressed === 'ArrowDown') ||
      (inputValue === minimumValue && lastPressed === 'ArrowDown')
    )
      return;
    const { value } = event.target;
    const newCount = value ? parseInt(value, 10) : '';
    setInputValue(newCount);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const { value } = event.target as HTMLInputElement;
    const newCount = value ? parseInt(value, 10) : '';
    setLastPressed(key);

    if (
      (key === 'ArrowUp' && (newCount as number) > maximumValue) ||
      (key === 'ArrowUp' && disableAdd)
    ) {
      setInputValue(maximumValue);
      setIsLimitingUp(true);
      return;
    } else if (key === 'ArrowDown' && (newCount as number) <= minimumValue) {
      setInputValue(minimumValue);
      setIsLimitingDown(true);
      return;
    }

    setIsLimitingDown(false);
    setIsLimitingUp(false);
  };

  return (
    <div className="counter__buttons-container">
      <button
        onClick={handleDecrease}
        className="counter__button decrease"
        disabled={inputValue === minimumValue}
      >
        -
      </button>
      <input
        maxLength={2}
        value={inputValue}
        onChange={handleInputChange} // Change onInput to onChange
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        type="number"
        className="counter__number"
      />
      <button
        onClick={handleIncrease}
        className="counter__button increase"
        disabled={(inputValue as number) >= maximumValue || disableAdd}
      >
        +
      </button>
    </div>
  );
}

export default Counter;
