/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useState, useEffect, useContext } from 'react';
import type { ReactNode } from 'react';

import API from '../api/api';

import { turnHumanReadableStringToJson } from '../helpers/humanReadable';

import { EcomPaymentService } from '@roller/ecom-payments';
import type { IPaymentAction } from '@roller/ecom-payments';

import { ModalContext } from './modal';
import type { ModalContextValueType } from './modal';

import { StepsContext } from './steps';
import type { StepsContextValueType } from './steps';

import type {
  VenueInfo,
  BookingData,
  BookingItem,
  Product,
  BookingDetails,
  PaymentHandlers,
  DetailBookingItem,
  SubProduct
} from './data.d';

import RedirectWarning from '../components/Alerts/RedirectWarning';
import ErrorAlert from '../components/Alerts/Error';
import { removeEmojis } from '../helpers/input';

export * from './data.d';

export type DataContextValueType = {
  bookingData: [BookingData, React.Dispatch<React.SetStateAction<BookingData>>];
  bookingItems: [BookingItem[], React.Dispatch<React.SetStateAction<BookingItem[]>>];
  bookingDetails: [
    BookingDetails | null,
    React.Dispatch<React.SetStateAction<BookingDetails | null>>
  ];
  editPackage: [BookingItem | null, React.Dispatch<React.SetStateAction<BookingItem | null>>];
  venueInfo: VenueInfo;
  productData: Product[];
  foodProductData: Product[];
  decorationProductData: Product[];
  extraProductData: Product[];
  productId: string;
  selectedPackage: Product | null;
  setupPayment: (handlers: PaymentHandlers) => void;
  getInitialEditingData: () => void;
  isEditMode: boolean;
  externalId: string;
  paymentReady: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  partyNameEl: ReactNode | null;
  partyNameString: string | null;
  isDecreasingJumpers: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

type DataContextProps = {
  children: ReactNode;
};

export const DataContext = createContext<DataContextValueType | null>(null);

export default ({ children }: DataContextProps) => {
  const initalBookingData = {
    customer: {
      email: '',
      phone: ''
    },
    extra: {
      bookingDate: '',
      startTime: '',
      guestOfHonorCount: 1,
      jumpersNumber: 10,
      nonJumpersNumber: 0,
      guestOfHonorFirstName: '',
      guestOfHonorDob: '',
      totalCost: 0
    }
  };

  const [bookingData, setBookingData] = useState<BookingData>(initalBookingData);
  const [venueInfo, setVenueInfo] = useState<VenueInfo>({});
  const [productData, setProductData] = useState([]);
  const [editPackage, setEditPackage] = useState<BookingItem | null>(null);
  const [foodProductData, setFoodProductData] = useState([]);
  const [decorationProductData, setDecorationProductData] = useState([]);
  const [extraProductData, setExtraProductData] = useState([]);
  const [productId, setProductId] = useState('');
  const [selectedPackage, setSelectedPackage] = useState<Product | null>(null);
  const [bookingItems, setBookingItems] = useState<BookingItem[]>([]);
  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(null);
  const [externalId, setExternalId] = useState('');
  const [categoriesFilled, setCategoriesFilled] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [partyNameEl, setPartyNameEl] = useState<ReactNode | null>(null);
  const [partyNameString, setPartyNameString] = useState<string | null>(null);
  const [isDecreasingJumpers, setIsDecreasingJumpers] = useState(false);

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const { activeStep } = useContext(StepsContext) as StepsContextValueType;

  const getDataProductId = async () => {
    const productId = await API.getProductId();
    if (productId) {
      setProductId(productId);
      return productId;
    } else {
      displayOnLoadErrorModal();
    }
  };

  const displayRedirectWarning = () => {
    openModal(
      <RedirectWarning
        title="Oops! This isn’t where the party’s at."
        description="Hold tight and we’ll bounce you back to the right page."
        onCloseModal={closeModal}
        button={'exit'}
      />,
      false
    );
  };

  const displayOnLoadErrorModal = () => {
    const isRootPage = window.location.pathname === '/';
    if (isRootPage) {
      openModal(
        <ErrorAlert
          onCloseModal={closeModal}
          title="Package not found"
          description="This package does not exist for this location"
          buttons={['exitBooking']}
        />,
        false
      );
    } else {
      openModal(
        <ErrorAlert
          onCloseModal={closeModal}
          title="Invalid URL"
          description=""
          buttons={['exitBooking']}
        />,
        false
      );
    }
  };

  const bootstrapPayment = async (venueInfo: VenueInfo, paymentService: EcomPaymentService) => {
    const config = {
      integrationId: venueInfo.paymentSettings?.integrationId || '',
      configurationId: venueInfo.paymentSettings?.configurationId || '',
      apiUrl: venueInfo.paymentSettings?.apiUrl || ''
    };

    const actions = {
      // log: {
      //   info: (message: string) => console.log(message),
      //   warn: (message: string) => console.log(message),
      //   error: (message: string) => console.log(message)
      // },
      http: {
        post: (url: string, data: any) =>
          fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then((response: any) => response.json()),
        get: (url: string) => fetch(url).then((response: any) => response.json())
      }
    } as IPaymentAction;

    await paymentService.bootstrap(config, actions);
  };

  const setupPayment = async (handlers: PaymentHandlers) => {
    const paymentService = new EcomPaymentService();

    await bootstrapPayment(venueInfo, paymentService);

    const paymentRequest = {
      jwt: bookingData.extra.paymentJwt || '',
      hasRecurringBilling: false,
      redirectUrl: window.location.origin + '/confirmation',
      paymentContainerDivId: 'payment-container',
      handlers
    };

    paymentService.setup(paymentRequest).then(
      () => console.log('payment ready'),
      (error: any) => console.log(error)
    );
  };

  const syncLocalStorage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchPartyName = urlParams.get('party-name');

    const storedExtraInfo = localStorage.getItem(`extraParkInfo-${searchPartyName}`);
    if (storedExtraInfo) {
      const extraInfo = JSON.parse(storedExtraInfo);

      setBookingData(prevState => ({
        ...prevState,
        extra: {
          ...prevState.extra,
          parkPhoneNumber: extraInfo.phoneNumber,
          partyCapacity: parseInt(extraInfo.partyCapacity),
          rollerName: extraInfo.rollerName,
          rollerVenueName: extraInfo.rollerVenueName,
          parkNameUrl: extraInfo.parkNameUrl,
          guestServicesTextNumber: extraInfo.guestServicesTextNumber
        }
      }));
    }
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000000);
    return `${timestamp}-${random}`;
  };

  const getProducts = async () => {
    if (productData.length) return;
    try {
      const productDetailResponse = await API.fetchProductDetail();
      setProductData(productDetailResponse);
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const getAddonCategories = async () => {
    if (categoriesFilled) return;
    try {
      const categorizedProducts = await API.getCategorizedProducts();

      setFoodProductData(categorizedProducts.foodProductData);
      setDecorationProductData(categorizedProducts.decorationProductData);
      setExtraProductData(categorizedProducts.extraProductData);
      setCategoriesFilled(true);
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const handleDateAndTimeData = async () => {
    showLoading();
    await getProducts();
    hideLoading();
  };

  const handleAddonsData = async () => {
    await getAddonCategories();
  };

  const parseBookingItems = async (items: DetailBookingItem[], productData: Product[]) => {
    let bookingItems: BookingItem[] = [];
    // const urlParams = new URLSearchParams(window.location.search);
    const prodID = await getDataProductId();
    const selectedPackage = productData.find((product: Product) => product.id === prodID);
    const packageItems = selectedPackage?.products[0].packageItems;

    items.forEach(responseItem => {
      let subproduct: SubProduct | undefined;

      const product = productData.find(product => {
        const selectedSubproduct = product.products.find(
          subproduct => subproduct.id === responseItem.productId.toString()
        );
        subproduct = selectedSubproduct;
        return selectedSubproduct;
      });

      const isIncluded = !!packageItems?.find(
        includedItem => includedItem.parentProductId === product?.id
      );

      if (responseItem.startTime) return;

      bookingItems.push({
        quantity: responseItem.quantity,
        name: subproduct?.name || '',
        cost: subproduct?.cost || 0,
        productId: responseItem.productId.toString(),
        isIncluded: isIncluded,
        index: undefined,
        parentId: product?.id || '',
        parentName: product?.name || '',
        tax: subproduct?.tax || 0,
        bookingDate: responseItem.bookingDate,
        startTime: responseItem.startTime || ''
      });
    });

    bookingItems = bookingItems.map((bookingItem, index) => {
      const previousWithSameParentId = bookingItems
        .slice(0, index)
        .filter(item => item.parentId === bookingItem.parentId);
      const allWithSameParentId = bookingItems.filter(
        item => item.parentId === bookingItem.parentId
      );

      const bookingItemIndex =
        bookingItem.isIncluded && allWithSameParentId.length > 1
          ? previousWithSameParentId.length
          : bookingItem.name === bookingItem.parentName
          ? undefined
          : 0;

      return {
        ...bookingItem,
        index: bookingItem.isIncluded ? bookingItemIndex : undefined
      };
    });

    return bookingItems;
  };

  const parseNames = (partyName: string): string[] => {
    if (!partyName) return [];
    const names = partyName.split(' and ');

    if (names.length === 1) {
      return [partyName.split("'")[0]];
    }

    if (!names[0].includes(',')) {
      return [names[0], names[1].split("'")[0]];
    }

    return [...names[0].split(', '), names[1].split("'")[0]];
  };

  const getInitialEditingData = async () => {
    try {
      const uniqueId = generateUniqueId();
      setExternalId(uniqueId);
      const editBookingInfoResponse = await API.getEditBookingInfo();

      const { bookingInformation, customer, products, venue } = editBookingInfoResponse;

      setVenueInfo({
        ...venue,
        name: venue.name.replace('Sky Zone ', '').replace('SkyZone', '')
      });

      setProductData(products);

      const packageItem = bookingInformation.items.find(
        (item: DetailBookingItem) => item.startTime
      );

      setBookingDetails({ ...bookingInformation, customerInfo: customer });
      setEditPackage(packageItem);

      const parsedNames = parseNames(bookingInformation.name);
      const bookingNotes = turnHumanReadableStringToJson(bookingInformation.comments);

      setBookingData({
        ...bookingData,
        extra: {
          ...bookingData.extra,
          bookingDate: packageItem.bookingDate,
          startTime: packageItem.startTime,
          guestOfHonorCount: parsedNames.length,
          guestOfHonorFirstName: parsedNames[0],
          secondGuestOfHonorFirstName: parsedNames[1] || '',
          thirdGuestOfHonorFirstName: parsedNames[2] || '',
          guestOfHonorDob: bookingNotes?.guestOfHonorDob || '',
          secondGuestOfHonorDob: bookingNotes?.secondGuestOfHonorDob || '',
          thirdGuestOfHonorDob: bookingNotes?.thirdGuestOfHonorDob || '',
          jumpersNumber: packageItem.quantity,
          nonJumpersNumber: bookingNotes.nonJumpersNumber || 0
        }
      });

      const parsedBookingItems = await parseBookingItems(bookingInformation.items, products);
      setBookingItems(parsedBookingItems);
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const getInitialCreatingData = async () => {
    try {
      const uniqueId = generateUniqueId();
      setExternalId(uniqueId);

      const venueInfoResponse = await API.fetchVenueInfo();
      setVenueInfo({
        ...venueInfoResponse,
        name: venueInfoResponse.name.replace('Sky Zone ', '').replace('SkyZone', '')
      });
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const getInitialData = async () => {
    // const isRootPage = window.location.pathname === '/';
    const shouldSyncLocalStorage =
      window.location.pathname === '/review' || window.location.pathname === '/';

    await getInitialCreatingData();

    if (shouldSyncLocalStorage) {
      syncLocalStorage();
    }
  };

  const setPartyName = () => {
    const {
      guestOfHonorCount,
      guestOfHonorFirstName,
      secondGuestOfHonorFirstName,
      thirdGuestOfHonorFirstName
    } = bookingData.extra;
    let partyNameEl = null;
    let partyNameString = null;

    if (guestOfHonorCount === 1) {
      partyNameEl = (
        <span>
          <strong>{guestOfHonorFirstName}'s</strong> party{' '}
        </span>
      );
      partyNameString = `${removeEmojis(guestOfHonorFirstName)}'s party`;
    }

    if (guestOfHonorCount === 2 && secondGuestOfHonorFirstName) {
      partyNameEl = (
        <span>
          <strong>{guestOfHonorFirstName}</strong> and{' '}
          <strong>{secondGuestOfHonorFirstName}'s</strong> party
        </span>
      );
      partyNameString = `${removeEmojis(guestOfHonorFirstName)} and ${removeEmojis(
        secondGuestOfHonorFirstName
      )}'s party`;
    }

    if (guestOfHonorCount === 3 && secondGuestOfHonorFirstName && thirdGuestOfHonorFirstName) {
      partyNameEl = (
        <span>
          <strong>{guestOfHonorFirstName}</strong>, <strong>{secondGuestOfHonorFirstName}</strong>{' '}
          and <strong>{thirdGuestOfHonorFirstName}'s</strong> party
        </span>
      );
      partyNameString = `${removeEmojis(guestOfHonorFirstName)}, ${removeEmojis(
        secondGuestOfHonorFirstName
      )} and ${removeEmojis(thirdGuestOfHonorFirstName)}'s party`;
    }

    setPartyNameEl(partyNameEl);
    setPartyNameString(partyNameString);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const searchPartyName = urlParams.get('party-name');
        const searchVenueName = urlParams.get('venue-name');
        if (!searchPartyName || !searchVenueName) {
          displayRedirectWarning();
          return;
        }
        await API.loadCaptcha();
        await getInitialData();
        await getDataProductId();
      } catch (error) {
        openModal(
          <ErrorAlert
            title="Unknown error"
            description="Our server is returning an unknown error, please try again later"
            onCloseModal={closeModal}
            buttons={['exitBooking', 'reload']}
          />,
          false
        );
        console.error(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-date-time' && !bookingDetails) {
      handleDateAndTimeData();
    }

    if (activeStep && activeStep.value === 'select-addons') {
      handleAddonsData();
    }
  }, [activeStep]);

  useEffect(() => {
    if (productId && productData.length) {
      const selectedPackage = productData.find((product: Product) => product.id === productId);
      if (selectedPackage) {
        setSelectedPackage(selectedPackage);
        return;
      }

      openModal(
        <ErrorAlert
          onCloseModal={closeModal}
          title="Package not found"
          description="This package does not exist for this location"
          buttons={['exitBooking']}
        />,
        false
      );
    }
  }, [productId, productData.length]);

  useEffect(() => {
    setPartyName();
  }, [
    bookingData.extra.guestOfHonorFirstName,
    bookingData.extra.secondGuestOfHonorFirstName,
    bookingData.extra.thirdGuestOfHonorFirstName
  ]);

  const value: DataContextValueType = {
    bookingData: [bookingData, setBookingData],
    bookingItems: [bookingItems, setBookingItems],
    bookingDetails: [bookingDetails, setBookingDetails],
    editPackage: [editPackage, setEditPackage],
    paymentReady: [paymentReady, setPaymentReady],
    productData,
    foodProductData,
    decorationProductData,
    extraProductData,
    venueInfo,
    productId,
    selectedPackage,
    setupPayment,
    getInitialEditingData,
    isEditMode: !!bookingDetails,
    externalId,
    partyNameEl,
    partyNameString,
    isDecreasingJumpers: [isDecreasingJumpers, setIsDecreasingJumpers]
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
