import React, { useContext } from 'react';
import Alert from '../../../components/Alert';
import { DataContextValueType } from '../../../contexts/data';
import { DataContext } from '../../../contexts/data';

type AddonsPricingProps = {
  packageName?: string;
  packagePrice: number;
  addonsPrice: number;
  totalPrice: number;
};

const AddonsPricing = ({ packageName, packagePrice, addonsPrice }: AddonsPricingProps) => {
  const {
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="add-ons__total-wrapper">
      <div className="add-ons__sub-total container-sm">
        <div className="add-ons__total-row">
          <p className="total-row__total-label">{packageName}</p>
          <p className="total-row__total-value">${packagePrice.toFixed(2)}</p>
        </div>
        <div className="add-ons__total-row ">
          <p className="total-row__total-label">Add-ons</p>
          <p className="total-row__total-value">${addonsPrice.toFixed(2)}</p>
        </div>
      </div>
      <div className="add-ons__total container-sm">
        <p className="total-label">Subtotal</p>
        <p className="total-value">${bookingData.extra?.totalCost?.toFixed(2)}</p>
      </div>
      <div className="container-sm">
        <Alert
          className="steps-warning-message"
          title={<>Subtotal May Change</>}
          type="default-font-black"
        >
          The subtotal displayed on this step may not reflect the final total shown at checkout.
          Adjustments may reflect additional taxes due for add-on items and/or a balance change due
          to an applied discount code.
        </Alert>
      </div>
    </div>
  );
};

export default AddonsPricing;
