import React from 'react';
import type { ReactNode } from 'react';

import classnames from 'classnames';

type AlertProps = {
  children?: ReactNode;
  className?: string;
  type?: 'info' | 'default' | 'default-font-black' | 'error';
  title?: ReactNode | string;
};

function Alert({ children, title, className, type = 'default' }: AlertProps) {
  return (
    <div
      className={classnames({
        alert: true,
        [`alert--${type}`]: type,
        [`${className}`]: className
      })}
    >
      {title && <span className="alert__title">{title}</span>}
      <span className="alert__text">{children}</span>
    </div>
  );
}

export default Alert;
