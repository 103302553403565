import React, { useContext, useState, useEffect, useRef } from 'react';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType, Package, Session } from '../../../contexts/data';

import { ModalContext } from '../../../contexts/modal';
import type { ModalContextValueType } from '../../../contexts/modal';

import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';

import ErrorAlert from '../../../components/Alerts/Error';

import DateAndTimeDescription from './DateAndTimeDescription';
import DaySelector from './DaySelector';
import TimeSelector from './TimeSelector';
import DateAndTimeButtons from './DateAndTimeButtons';

import API from '../../../api/api';
import Alert from '../../../components/Alert';

type DateAndTimeProps = {
  onNext: () => void;
};

const DateAndTime = ({ onNext }: DateAndTimeProps) => {
  const {
    productId,
    bookingData: [bookingData, setBookingData],
    isEditMode,
    bookingDetails
  } = useContext(DataContext) as DataContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const {
    activeStep,
    goToPrevStep,
    stepWillChange: [stepWillChange, setStepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const [unavailableDays, setUnavailableDays] = useState<string[]>([]);
  const [showTimeAlert, setShowTimeAlert] = useState(false);
  const [showDateUnavailable, setShowDateUnavailable] = useState(false);
  const [availableSessions, setAvailableSesions] = useState<Session[]>([]);
  const [daySelected, setDaySelected] = useState<string>();
  const [timeSelected, setTimeSelected] = useState<string>();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [showDiscountNotice, setShowDiscountNotice] = useState(false);

  const [isOverVenueLimit, setIsOverVenueLimit] = useState(false);

  const discountNoticeRef = useRef<HTMLDivElement | null>(null);
  const timeTitleRef = useRef<HTMLHeadingElement | null>(null);
  const buttonsContainerRef = useRef<HTMLDivElement | null>(null);

  const getAvailableSessions = (sessions: Session[]) => {
    return sessions.filter(
      (session: Session) => session.capacityRemaining > 0 && session.onlineSalesOpen
    );
  };

  const onClickDay = async (date: Date | string, shouldScroll?: boolean) => {
    setShowDiscountNotice(false);
    setAvailableSesions([]);
    setTimeSelected('');
    const sixMonthsAfterToday: Date = new Date(Date.now() + 180 * 24 * 60 * 60 * 1000);

    if (new Date(date) > sixMonthsAfterToday) {
      setShowTimeAlert(true);
      return;
    }

    setShowTimeAlert(false);
    setShowDateUnavailable(false);
    setNextDisabled(true);
    showLoading();

    try {
      const dateUS = typeof date === 'string' ? '' : date.toLocaleDateString('en-US');
      const splitedDate = dateUS.split('/');

      const month = parseInt(splitedDate[0], 10);
      const day = parseInt(splitedDate[1], 10);
      const year = parseInt(splitedDate[2], 10);

      const dateFormated = typeof date === 'string' ? date : `${year}-${month}-${day}`;

      const query = `?date=${dateFormated}&productids=${productId}`;
      const productAvailabilityData = await API.fetchProductAvailability(query);

      if (productAvailabilityData.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={productAvailabilityData.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking', 'close']}
          />
        );
        return;
      }

      const packageData = productAvailabilityData.find(
        (item: Package) => item.type === 'partypackage'
      );

      const availableSessionsData = getAvailableSessions(packageData.sessions);

      if (!availableSessionsData.length) {
        setShowDateUnavailable(true);
        setUnavailableDays([...unavailableDays, dateFormated]);
        setDaySelected('');
        hideLoading();
        return;
      }

      hideLoading();
      setDaySelected(dateFormated);
      setAvailableSesions(availableSessionsData);
      if (shouldScroll) {
        if (isEditMode && bookingDetails) {
          if (bookingData.extra.bookingDate !== dateFormated) {
            setShowDiscountNotice(true);
            return;
          } else setShowDiscountNotice(false);
        }
        setTimeout(() => {
          const elementPosition =
            timeTitleRef.current &&
            timeTitleRef.current.getBoundingClientRect().top + window.scrollY;
          let offset = 100;
          if (window.innerWidth > 1024) offset = 200;

          if (elementPosition)
            window.scrollTo({
              top: elementPosition - offset,
              behavior: 'smooth'
            });
        }, 100);
      }
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  useEffect(() => {
    if (showDiscountNotice) {
      setTimeout(() => {
        if (discountNoticeRef.current) {
          const elementPosition =
            discountNoticeRef.current?.getBoundingClientRect().top + window.scrollY;
          let offset = 75;
          if (window.innerWidth > 1024) offset = 100;

          if (elementPosition)
            window.scrollTo({
              top: elementPosition - offset,
              behavior: 'smooth'
            });
        }
      }, 200);
    }
  }, [showDiscountNotice]);

  const handleEditLoad = async () => {
    await onClickDay(bookingData.extra.bookingDate);
    setTimeSelected(bookingData.extra.startTime);
    setNextDisabled(false);
  };

  const checkVenueLimit = (bookableCapacityRemaining: number) => {
    if (bookingData.extra.jumpersNumber > bookableCapacityRemaining) {
      return true;
    }

    return false;
  };

  const handleSelectTime = (ev: React.ChangeEvent<HTMLInputElement>, session: Session) => {
    const isOverVenueLimit = checkVenueLimit(session.allocations[0].bookableCapacityRemaining);

    setTimeSelected(ev.target.value);

    if (isOverVenueLimit) {
      setNextDisabled(true);
      setIsOverVenueLimit(true);
      return;
    }

    setIsOverVenueLimit(false);
    setNextDisabled(false);

    setTimeout(() => {
      if (buttonsContainerRef.current) {
        const elementRect = buttonsContainerRef.current.getBoundingClientRect();

        if (elementRect.bottom > window.innerHeight || elementRect.top < 0) {
          const elementPosition = elementRect.bottom + window.scrollY;

          window.scrollTo({
            top: elementPosition - window.innerHeight,
            behavior: 'smooth'
          });
        }
      }
    }, 100);
  };

  const handleResetStep = () => {
    setDaySelected('');
    setTimeSelected('');
  };

  const handleSave = (callback: () => void) => {
    if (!timeSelected || !daySelected) {
      setStepWillChange({ willChange: false });
      return;
    }

    setBookingData({
      ...bookingData,
      extra: {
        ...bookingData.extra,
        bookingDate: daySelected,
        startTime: timeSelected
      }
    });

    callback();
    return true;
  };

  const handleNext = async () => {
    handleSave(onNext);
  };

  const handlePrev = () => {
    if (!bookingData.extra.bookingDate) {
      handleResetStep();
    }
    goToPrevStep();
  };

  const handleStepChange = () => {
    if (!bookingData.extra.bookingDate) {
      handleResetStep();
    }

    stepWillChange.stepValue && goToStep(stepWillChange.stepValue);
  };

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-date-time' && isEditMode) {
      handleEditLoad();
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-date-time' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  return (
    <>
      <DateAndTimeDescription />

      <div className="date-and-time container-sm">
        <DaySelector
          showTimeAlert={showTimeAlert}
          showDateUnavailable={showDateUnavailable}
          onClickDay={onClickDay}
          daySelected={daySelected}
          unavailableDays={unavailableDays}
        />
        <div ref={discountNoticeRef}>
          {showDiscountNotice && (
            <Alert
              className="steps-warning-message"
              title={<>Discount may no longer apply</>}
              type="default-font-black"
            >
              Please note that any previously applied discount codes may no longer be valid after
              rescheduling your party date.
            </Alert>
          )}
        </div>

        {!!availableSessions.length && daySelected && (
          <TimeSelector
            timeTitleRef={timeTitleRef}
            daySelected={daySelected}
            timeSelected={timeSelected}
            availableSessions={availableSessions}
            handleSelectTime={handleSelectTime}
            isOverVenueLimit={isOverVenueLimit}
          />
        )}
      </div>

      <DateAndTimeButtons
        buttonsContainerRef={buttonsContainerRef}
        handlePrev={handlePrev}
        handleNext={handleNext}
        nextDisabled={nextDisabled}
      />
    </>
  );
};

export default DateAndTime;
